/*=========================================================================================
  File Name: moduleCompetitionMutations.js
  Description: Competition Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Competitions.unshift(item)
  },
  SET_Competition(state, Competitions) {
    state.Competitions = Competitions
  },
  UPDATE_Competition(state, Competition) {
    const CompetitionIndex = state.Competitions.findIndex((p) => p.ID == Competition.ID)
    Object.assign(state.Competitions[CompetitionIndex], Competition)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Competitions.findIndex((p) => p.ID == itemId)
    state.Competitions.splice(ItemIndex, 1)
},
}
