/*=========================================================================================
  File Name: moduleCompetition.js
  Description: Competition Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleCompetitionState.js'
import mutations from './moduleCompetitionMutations.js'
import actions from './moduleCompetitionActions.js'
import getters from './moduleCompetitionGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
