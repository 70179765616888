<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view " class="data-list-container competition">
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">

        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("DateFrom") }}</label>
          <datepicker
            placeholder="from"
            v-model="CompetitionSearch.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("DateTo") }}</label>
          <datepicker
            placeholder="from"
            v-model="CompetitionSearch.DateTo"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <div class="vx-row md:w-1/4 sm:w-1/2 w-full m-5 mb-4">
                <vs-checkbox
                  v-model="CompetitionSearch.IsWeekly"
                  :vs-value="true"
                  class="mr-4"
                  vs-name="type"
                  >{{$t('Weekly')}} 
                 <div style="background-color:#779eed;width: 30px; height: 20px;"> </div>
                  </vs-checkbox
                >
                <vs-checkbox
                  v-model="CompetitionSearch.IsMonthly"
                  :vs-value="true"
                  class="mr-4"
                  vs-name="type"
                  >{{$t('Monthly')}}
                 <div style="background-color:#3e9c72;width: 30px; height: 20px;"> </div>
                  </vs-checkbox
                >
              </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <vs-button
            class="my-6 rounded-sm"
            @click="CompetitionSearchFirst"
            color="warning"
            >{{ $t("Search") }}</vs-button
          >
        </div>
    
      </div>
    </vx-card>
    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in Competitions"
        :key="item.ID"
        
      >
        <vx-card style="background-color:#779eed" v-if="item.IsWeekly" class="vxCardPatient text-center cursor-pointer rounded-xl">
          <div class="vx-row ml-3 mr-3" style="justify-content: right">
            <feather-icon
              icon="TrashIcon"
              svgClasses="w-7 h-7 hover:text-primary stroke-current"
              @click="openConfirm(item)"
            />
          </div>
          <div class="vx-row m-3" style="justify-content: center">
          <h2>  {{$t('Date')}} :  {{ item.WeeklyDate }}</h2>
          </div>
          <div class="vx-col">
            <video
              :width="350"
              :height="200"
              id="videojs-sprite-thumbnails-player"
              class="video-js vjs-default-skin"
              data-setup='{"aspectRatio":"12:5"}'
              controls
            >
              <source :src="baseURL + item.WeeklyVideo.URL" type="video/mp4" />
              <!-- <source src="//vjs.zencdn.net/v/oceans.webm" type='video/webm'> -->
            </video>
            <div class="vx-col mb-3 mt-3">
              <h4 class="mb-2">
                {{$t('User')}} :  {{ item.WeeklyVideo.AppUser.ApplicationUser.FullName }}
              </h4>
              <h4 class="mb-2">
              {{$t('TotalRate')}} :  {{ item.TotalRate}}
              </h4>
              <u style="size:20px" class="mb-2" @click="GotoVideoDetails(item.WeeklyVideoID)">
              {{$t('GotoVideoDetails')}}
              </u>
            </div>
          </div>

        </vx-card>



        <vx-card v-else style="background-color:#3e9c72"  class="vxCardPatient text-center cursor-pointer rounded-xl">
          <div class="vx-row ml-3 mr-3" style="justify-content: right">
            <feather-icon
              icon="TrashIcon"
              svgClasses="w-7 h-7 hover:text-primary stroke-current"
              @click="openConfirm(item)"
            />
          </div>
          <div class="vx-row m-3" style="justify-content: center">
           <h2> {{$t('Date')}} :  {{ item.MonthlyDate }}</h2>
          </div>
          <div class="vx-col">
            <video
              :width="350"
              :height="200"
              id="videojs-sprite-thumbnails-player"
              class="video-js vjs-default-skin"
              data-setup='{"aspectRatio":"12:5"}'
              controls
            >
              <source :src="baseURL + item.MonthlyVideo.URL" type="video/mp4" />
              <!-- <source src="//vjs.zencdn.net/v/oceans.webm" type='video/webm'> -->
            </video>

            <div class="vx-col mb-3 mt-3">
              <h4 class="mb-2">
              {{$t('User')}} :  {{ item.MonthlyVideo.AppUser.ApplicationUser.FullName }}
              </h4>
              <h4 class="mb-2">
              {{$t('TotalRate')}} :  {{ item.TotalRate}}
              </h4>
              <u style="size:20px" class="mb-2" @click="GotoVideoDetails(item.MonthlyVideoID)">
              {{$t('GotoVideoDetails')}}
              </u>
            </div>
          </div>

        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
import moduleCompetition from "@/store/Competition/moduleCompetition.js";
import { domain } from "@/gloabelConstant.js";
 import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker
  },

  data() {
    return {
      search: {
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20,
      },
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
    CompetitionSearch() {
      debugger;
      return this.$store.state.CompetitionList.CompetitionSearchObj;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Competitions() {
      return this.$store.state.CompetitionList.Competitions;
    },
  },

  methods: {
    GotoVideoDetails(videoID){
      this.$router.push({ name: 'Video_Edit', params:{ID:videoID} });
    },
    CompetitionDataSearch() {
      debugger;
      this.$store
        .dispatch("CompetitionList/SearchCompetition", this.CompetitionSearch)
        .then((res) => {
          if (
            document.documentElement.scrollHeight <= window.innerHeight &&
            res.data.Data.length >= this.CompetitionSearch.PageSize
          ) {
            this.CompetitionSearch.PageNumber++;
            this.CompetitionDataSearch();
          }
        });
    },
    CompetitionSearchFirst() {
      debugger;
      this.CompetitionSearch.PageNumber = 1;
      this.CompetitionDataSearch();
    },
    addNewData() {
      // this.sidebarData = {};
      this.$router.push({ name: "Competition-add/Edit" });
    },

    ShowCompetitionSurgeries(data) {
      this.$router.push({
        name: "CompetitionSurgeries",
        params: { ID: data.ID },
      });
    },
    ShowPatientReservationSurgeries(data) {
      this.$router.push({
        name: "PatientResevationSurgeries",
        params: { ID: data.ID },
      });
    },
    ShowCompetitionSpecialties(data) {
      this.$router.push({
        name: "CompetitionSpecialities",
        params: { ID: data.ID },
      });
    },
    ShowCompetitionDoctors(data) {
      this.$router.push({
        name: "CompetitionDoctors",
        params: { ID: data.ID },
      });
    },

    deleteData(data) {
      debugger;
      this.$store.dispatch("CompetitionList/DeleteCompetition", data).catch((err) => {
        console.error(err);
      });
    },

    editData(data) {
      this.$router.push({ name: "Competition_Edit", params: { ID: data.ID } });
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });
    },
  },
  destroyed() {
    window.onscroll = () => {};
  },
  created() {
    if (!moduleCompetition.isRegistered) {
      this.$store.registerModule("CompetitionList", moduleCompetition);
      moduleCompetition.isRegistered = true;
    }
  

    this.CompetitionSearch.IsWeekly = true;
      this.CompetitionSearch.IsMonthly = true;
    this.$store.commit("CompetitionList/SET_Competition", []);
    this.CompetitionSearchFirst();
    // this.$store.dispatch("CompetitionList/GetAllCompetitions");
  },
};
</script>

<style lang="scss">
  .competition h2 ,h3,h4,u{
   color: white;
   size: 50px;
  }
#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
