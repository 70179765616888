/*=========================================================================================
  File Name: moduleCompetitionActions.js
  Description: Competition Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddCompetition({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Competition/AddCompetition", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllCompetitions({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Competition/GetAll")
        .then((response) => {
          commit('SET_Competition', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  SearchCompetition({ commit },search) {
    return new Promise((resolve, reject) => {
      axios.post("api/Competition/SearchCompetition",search)
        .then((response) => {
          commit('SET_Competition', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateCompetition(context, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Competition/UpdateCompetition", item)
        .then((response) => {
          // commit('UPDATE_Competition', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetCompetition(context, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("api/Competition/GetCompetition?ID="+ itemid)
        .then((response) => {
          debugger
          // commit('UPDATE_Competition', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteCompetition({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Competition/DeleteCompetition?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  
  DeleteCompetitionComment(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Competition/DeleteCompetitionComment?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  DeleteCompetitionLike(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Competition/DeleteCompetitionLike?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  DeleteCompetitionRate(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Competition/DeleteCompetitionRate?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  DeleteCompetitionFavorit(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Competition/DeleteCompetitionFavorit?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
